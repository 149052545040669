<template>
    <Card title="苏苏说天气" url="/hot">
        <div class="clickable weather">
            <img v-for="item in list" :key="item.code" :src="item.face_img" alt="" @click="openArticleDetail(item)">
        </div>
    </Card>
</template>
<script>
import article from '@/mixins/article'
export default {
    mixins: [article],
    props: {
        list: {
            type: Array,
            default: () => []
        }
    }
}
</script>

<style lang="less" scoped>

.weather {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 12px;

    img {
        width: 100%;
    }
}
</style>