<template>
    <div class="card">
        <div class="left tab-bar">
            <div class="clickable tab" @click="active = 'radar'" :class="active === 'radar' ? 'active' : ''">
                雷达图
            </div>
            <div class="clickable tab" @click="active = 'cloud'" :class="active === 'cloud' ? 'active' : ''">
                云图
            </div>
        </div>
        <div v-if="active === 'radar'" @click="$router.push('/radar-detail')">
            <img  :src="current.url" alt="">
        </div>
       <div v-if="active === 'cloud'" @click="$router.push('/cloud-detail')">
            <img  :src="currentCloud.url" alt="">
        </div>
        
    </div>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    data() {
        return {
            active: 'radar',
        }
    },
    computed: {
        ...mapGetters('radar', ['current']),
        ...mapGetters('cloud', ['currentCloud'])
    },
    mounted() {
        this.getList();
        this.getCloudList();
    },
    methods: {
        ...mapActions('radar', ['getList']),
        ...mapActions('cloud', ['getCloudList'])
    }
}
</script>

<style lang="less" scoped>

.card {
    background-color: #fff;

    .tab-bar {
        .tab {
            flex: 1;
            padding: 12px 24px;
            text-align: center;
        }
        .active {
            color: var(--theme-color);
            font-weight: bold;
            border-bottom: 2px solid var(--theme-color);
        }
    }

    img {
        display: block;
        width: 95%;
        height: 200px;
        object-fit: cover;
        margin: 12px auto;
        cursor: pointer;
    }
}
</style>