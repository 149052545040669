<template>
  <div class="card">
    <div class="between date" style="margin-top: 12px">
      <div v-for="(item, index) in hours" :key="index" class="date-item">
        <div>
          {{ item.hours }}
        </div>
      </div>
    </div>
    <div class="between" style="margin-top: 8px">
      <div v-for="(item, index) in hours" :key="index">
        <div style="margin-bottom: 8px">
          {{ item.wea }}
        </div>

        <img
          :src="`/static/common/img/meteor/weather/daynight/day_${item.wea_img}.png`"
          alt=""
        />

        <div style="margin-top: 8px">{{ item.tem }}℃</div>

        <div style="margin-bottom: 8px; margin-top: 8px">
          {{ item.win }}
        </div>
        <div>
          {{ item.win_speed }}
        </div>
      </div>
    </div>
    <div id="temp-line-chart"></div>
  </div>
</template>


<script>
import * as echarts from "echarts/core";
import { GridComponent } from "echarts/components";
import { LineChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
echarts.use([GridComponent, LineChart, CanvasRenderer]);

import { mapState } from "vuex";
import { fetchWeather } from "@/api/weather";

export default {
  data() {
    return {
      weekData: [],
    };
  },

  computed: {
    ...mapState("city", ["city"]),

    today() {
      return this.weekData.length > 0 ? this.weekData[0] : {};
    },
    hours() {
      return Array.isArray(this.today.hours)
        ? this.today.hours.filter((item, index) => index % 3 === 0)
        : [];
    },
  },
  watch: {
    city() {
      this.getData();
    },
    hours() {
      if (this.hours.length > 0) {
        this.initChart(this.hours);
      }
    },
  },
  mounted() {
    this.getData();
  },

  methods: {
    getData() {
      fetchWeather({
        city: this.city,
      }).then((res) => {
        if (res && Array.isArray(res.data)) {
          this.weekData = res.data;
        }
      });
    },

    initChart(list = []) {
      var chartDom = document.getElementById("temp-line-chart");
      if (!this.chart && chartDom) {
        this.chart = echarts.init(chartDom);
      }

      console.log("this.list", this.list);
      var option;

      option = {
        color: ["#238b88", "#e44e35"],
        xAxis: {
          show: false,
          boundaryGap: false,
          axisLine: {
            show: true,
            lineStyle: {
              // color: "transparent",
            },
          },

          splitLine: {
            lineStyle: {
              width: 0,
            },
          },
          type: "category",
          data: list.map((item) => {
            return {
              value: item.hours,
              textStyle: {
                // color: "#fff",
              },
            };
          }),
        },
        grid: {
          left: "5%",
          right: "5%",
          top: "35%",
          bottom: 0,
        },
        yAxis: {
          show: false,
          splitLine: {
            show: false,
            lineStyle: {
              width: 0,
            },
          },
        },
        series: [
          {
            data: list.map((item) => parseFloat(item.tem)),
            type: "line",
            smooth: true,
            label: {
              show: true,
              color: "#999",
              formatter: `{c}°`,
            },
          },
        ],
      };

      option &&
        this.chart &&
        this.chart.setOption &&
        this.chart.setOption(option);
    },
  },
};
</script>

<style lang="less" scoped>
.card {
  font-size: 12px;
}

.date {
  background-color: var(--theme-color);
  color: #fff;
  padding: 8px 0;

  .date-item {
    line-height: 1em;
    border-right: 1px solid #fff;
  }
  .date-item:last-child {
    border-right-width: 0;
  }
}
.between {
  & > div {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  img {
    width: 48px;
  }
}

#weather {
  height: 24px;
}

#temp-line-chart {
  height: 60px;
}
</style>