<template>
  <div class="card">
    <div class="left tab-bar">
      <div class="clickable tab active">逐3小时天气预报</div>
      <div class="clickable tab" @click="$router.push('/weather-forecast')">
        一周预报
      </div>
    </div>

    <WeatherHour />
    <!-- <div class="list">
            <div class="item" v-for="(item, index) in list" :key="index">
                <div class="hour">{{ getHour(item.forecastTime) }}</div>
                <img :src="`/static/common/img/meteor/weather/orange/${getImage(item)}.png`" alt="">
            </div>
        </div>
        <TempChart v-if="list.length > 0" :list="list" />
        <div class="list">
            <div class="item" v-for="(item, index) in list" :key="index">
                <div class="wind">{{item.windDCN}}{{item.windSCN}}</div>
            </div>
        </div> -->
  </div>
</template>

<script>
import WeatherHour from "./weahter-hour.vue";
// import TempChart from './temp-chart.vue'
// import { fetchHourWeather } from '@/api/weather'
export default {
  components: {
    WeatherHour,
    // TempChart,
  },
  data() {
    return {
      active: "hour",
      list: [],
    };
  },
  // mounted() {
  //     fetchHourWeather({
  //         stationId: 58238
  //     }).then(res => {
  //         if (Array.isArray(res)) {
  //             this.list = res;
  //         }
  //         console.log('hour weather', res)
  //     })
  // },
  methods: {
    getHour(str) {
      const obj = new Date(str);
      return String(obj.getHours()).padStart(2, "0") + "时";
    },
    getImage(p) {
      if (parseFloat(p.rain) > 0) {
        return "yu";
      } else if (parseFloat(p.totalCloud) < 30) {
        return "qing";
      } else if (
        parseFloat(p.totalCloud) >= 30 &&
        parseFloat(p.totalCloud) < 70
      ) {
        return "yun";
      } else {
        return "yin";
      }
    },
  },
};
</script>

<style lang="less" scoped>
.card {
  background-color: #fff;

  .tab-bar {
    .tab {
      padding: 12px 24px;
    }
    .active {
      color: var(--theme-color);
      font-weight: bold;
      border-bottom: 2px solid var(--theme-color);
    }
  }

  .list {
    display: flex;
    justify-content: space-between;

    .item {
      flex: 1;
      text-align: center;
      padding: 16px 0;

      .hour {
        font-weight: bold;
        margin-bottom: 8px;
      }
      img {
        width: 20px;
        margin-bottom: 8px;
      }
      .wind {
        color: #999;
        font-size: 12px;
      }
    }
  }
}
</style>