<template>
    <Card title="实时空气质量指数" :extra="false">
        <div id="air-chart"></div>
        <div class="date">更新时间：{{now}}{{aqi.update_time}}</div>
    </Card>
</template>




<script>
import * as echarts from 'echarts';

import { fetchWeather } from '@/api/weather'
export default {
    data() {
        return {
            now: '',
            aqi: {}
        }
    },
    watch: {
        aqi() {
            this.initChart();
        }
    },
    mounted() {
        const now = new Date();
        this.now = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')} `
        fetchWeather({
            city: '南京'
        }).then(res => {
            console.log('nanjing', res)
            if (res && res.aqi) {
                this.aqi = res.aqi;
                this.initChart();
            }
        })
    },
    methods: {
        initChart() {
            const data = this.aqi;
            var chartDom = document.getElementById('air-chart');
            var myChart = echarts.init(chartDom);
            var option;

            option = {
                grid: {
                    top: '20%',
                    bottom: '5%'
                },
                series: [
                    {
                        type: 'gauge',
                        startAngle: 180,
                        endAngle: 0,
                        min: 0,
                        max: 500,
                        splitNumber: 5,
                        radius: '130px',
                        center: ['50%', '80%'],
                        axisLine: {
                            lineStyle: {
                                width: 4,
                                color: [
                                    [50 / 1300, '#059A65'],
                                    [150 / 1300, '#85bd4b'],
                                    [300 / 1300, '#ffdd33'],
                                    [500 / 1300, '#fe9633'],
                                    [800 / 1300, '#ca0035'],
                                    [1, '#680097'],
                                ]
                            }
                        },
                        pointer: {
                            show: false,
                        },
                        axisTick: {
                            length: 12,
                            lineStyle: {
                                color: 'auto',
                                width: 2
                            }
                        },
                        splitLine: {
                            length: 20,
                            lineStyle: {
                                color: 'auto',
                                width: 5
                            }
                        },
                        axisLabel: {
                            color: '#464646',
                            fontSize: 12,
                            distance: -50,
                            formatter(value) {
                                return value;
                            }
                            // formatter: function (value) {
                                // if (value === 25) {
                                    // return '优';
                                // } else if (value === 75) {
                                    // return '良';
                                // } else if (value === 125) {
                                    // return '轻度污染';
                                // } else if (value === 175) {
                                    // return '中度污染';
                                // } else if (value === 250) {
                                    // return '重度污染'
                                // } else if (value === 400) {
                                    // return '严重污染'
                                // }
                                // return '';
                            // }
                        },
                        title: {
                            offsetCenter: [0, '0%'],
                            fontSize: 14,
                            color: 'auto'
                        },
                        detail: {
                            fontSize: 32,
                            offsetCenter: [0, '-30%'],
                            valueAnimation: true,
                            formatter: function (value) {
                                return Math.round(value)
                            },
                            color: 'auto'
                        },
                        data: [
                            {
                                name: `空气质量：${data.air_level}`,
                                value: parseInt(data.air),
                            }
                        ]
                    }
                ]
            };

            option && myChart.setOption(option);


        }
    }
}
</script>

<style lang="less" scoped>

#air-chart {
    height: 200px;
}
.date {
    color: #999;
    font-size: 13px;
    text-align: center;
}
</style>