<template>
    <div class="around category">
        <router-link v-for="item in category" :key="item.title" :to="item.path">
            <img :src="item.icon" alt="">
            <div>{{ item.title }}</div>
        </router-link>
    </div>
</template>

<script>
export default {
    data() {
        return {
            category: [{
                title: '高速公路天气',
                icon: require('@/assets/category1.jpg'),
                path: '/traffic',
            }, {
                title: '机场天气预报',
                icon: require('@/assets/category1.jpg'),
                path: '/traffic'
            }, {
                title: '农业气象',
                icon: require('@/assets/category1.jpg'),
                path: '/farming'
            }, {
                title: '景点天气预报',
                icon: require('@/assets/category1.jpg'),
                path: '/travel'
            }, {
                title: '空气质量预报',
                icon: require('@/assets/category1.jpg'),
                path: '/environment',
            }]
        }
    }
}
</script>

<style lang="less" scoped>
.category {
    padding: 12px;
    background-color: #fff;
    a {
    color: #666;
        
    }
    img {
        width: 80px;
        margin-bottom: 4px;
    }
}
</style>