<template>
    <Card title="热图排行">
        <div class="list">
            <a href="#" class="item" v-for="(item, index) in list" :key="item.code"
                @click.prevent="openArticleDetail(item)">{{ index + 1 }}.{{item.title}}
            </a>
        </div>
    </Card>
</template>

<script>
import article from '@/mixins/article'
export default {
    mixins: [article],

    props: {
        list: {
            type: Array,
            default: () => []
        }
    }
}
</script>

<style lang="less" scoped>

.list {
    .item {
        display: block;
        color: #717171;
        margin-bottom: 4px;
        font-size: 13px;
        cursor: pointer;

        &:last-child {
            margin-bottom: 0;
        }
    }
}
</style>