<template>
  <div class="wrapper">
    <img class="bg" src="../../../assets/home/map.png" alt="" />
    <img class="js" src="../../../assets/home/js.png" alt="" />
    <img class="wind" src="../../../assets/home/wind.png" alt="" />

    <div
      class="city"
      v-for="item in divs"
      :key="item.name"
      :style="{
        height: item.height + 'px',
        width: item.width + 'px',
        top: item.y + 'px',
        left: item.x + 'px',
      }"
      @mouseover="openCityDetail(item.y + -60, item.x + 60, item.name)"
      @mouseleave="visible = false"
    ></div>

    <div
      class="city-detail"
      v-if="visible"
      :style="{ left: left + 'px', top: top + 'px' }"
    >
      <div>
        <span style="color: var(--theme-color); font-size: 1.2em">{{
          cityName
        }}</span>
        <!-- <span style="padding-left: 8px"> 24小时预报 </span> -->
      </div>

      <div class="info">
        <div class="name">
          <img v-if="selectedCityWeather.wea_img"
            :src="`/static/common/img/meteor/weather/mango/${selectedCityWeather.wea_img}.png`"
            alt=""
          />
        </div>
        <div class="tem">
          <div>
            {{ selectedCityWeather.tem}}
          </div>
          <div>
            <div>℃</div>
            <div>{{ selectedCityWeather.wea }}</div>
          </div>
        </div>
        
      </div>
      <div>
        {{
          Array.isArray(selectedCityWeather.win)
            ? selectedCityWeather.win[0]
            : ""
        }}{{ selectedCityWeather.win_speed }}
      </div>

      <!-- <div style="padding-top: 8px">
        <span style="color: #e44e35"> {{ selectedCityWeather.tempMin }}℃ </span>
        -
        <span> {{ selectedCityWeather.tempMax }}℃ </span>
      </div>

      <div style="padding-top: 8px">
        {{ selectedCityWeather.windDCN }} {{ selectedCityWeather.windSCN }}
      </div> -->
    </div>

    <div class="city-name">{{ city }}</div>
    <div class="alert" @click="$router.push('/alert-detail')">
      <div class="empty">
        江苏省气象台
        <span style="color: #f00">
          {{ provinceList.length > 0 ? provinceList.length + "个" : "无" }}
        </span>

        预警
      </div>
      <div class="empty">
        全省市县
        <span style="color: #f00">
          {{ cityList.length > 0 ? cityList.length + "个" : "无" }}
        </span>
        预警
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { fetchWeather } from "@/api/weather";
// import { fetchJSCityWeather } from "@/api/weather";
export default {
  data() {
    return {
      weather: [],
      top: 0,
      left: 0,
      cityName: "",
      visible: false,
      selectedCityWeather: {},

      divs: [
        {
          x: 496,
          y: 90,
          height: 24,
          width: 48,
          name: "徐州",
        },
        {
          x: 616,
          y: 64,
          height: 24,
          width: 48,
          name: "连云港",
        },
        {
          x: 560,
          y: 152,
          height: 24,
          width: 40,
          name: "宿迁",
        },
        {
          x: 600,
          y: 152,
          height: 24,
          width: 40,
          name: "淮安",
        },
        {
          x: 674,
          y: 142,
          height: 24,
          width: 40,
          name: "盐城",
        },
        {
          x: 648,
          y: 208,
          height: 24,
          width: 40,
          name: "扬州",
        },
        {
          x: 684,
          y: 248,
          height: 24,
          width: 40,
          name: "泰州",
        },
        {
          x: 770,
          y: 272,
          height: 24,
          width: 40,
          name: "南通",
        },
        {
          x: 584,
          y: 300,
          height: 24,
          width: 40,
          name: "南京",
        },
        {
          x: 630,
          y: 292,
          height: 24,
          width: 40,
          name: "镇江",
        },
        {
          x: 652,
          y: 350,
          height: 24,
          width: 40,
          name: "常州",
        },
        {
          x: 709,
          y: 318,
          height: 24,
          width: 40,
          name: "无锡",
        },
        {
          x: 742,
          y: 352,
          height: 24,
          width: 40,
          name: "苏州",
        },
      ],
    };
  },

  watch: {
    visible() {
      if (this.visible) {
        fetchWeather({
          city: this.cityName,
        }).then((res) => {
          if (res && Array.isArray(res.data) && res.data.length > 0) {
            this.selectedCityWeather = res.data[0];
          }
        });
      }
    },
  },

  computed: {
    ...mapState("city", ["city", "list"]),
    ...mapGetters("alert", ["provinceList", "cityList"]),
  },

  mounted() {
    //   fetchJSCityWeather().then((res) => {
    //     console.log("js city", res);
    //     if (Array.isArray(res)) {
    //       this.weather = res;
    //     }
    //   });

    this.getList();
  },

  methods: {
    openCityDetail(top, left, name) {
      this.top = top;
      this.left = left;
      this.cityName = name;
      this.visible = true;
    },

    ...mapActions("alert", ["getList"]),
  },
};
</script>

<style lang="less" scoped>
.bg {
  height: 398px;
  width: 100%;
}
.js {
  position: absolute;
  top: 10px;
  right: 18px;
  height: 392px;
}
.wind {
  position: absolute;
  top: 45%;
  right: 0;
  width: 98px;
  height: 65px;
}
.wrapper {
  position: relative;
  color: #fff;
}
.city {
  position: absolute;
  // background-color: rgba(0, 0, 0, .2);
  cursor: pointer;
}

.city-detail {
  z-index:99999;
  position: absolute;
  background-color: #fff;
  color: #000;
  padding: 16px 20px;
  border-radius: 8px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);
}

.city-name {
  position: absolute;
  top: 12px;
  left: 12px;
  z-index: 99;
  font-size: 44px;
  line-height: 1em;
}
.alert {
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 99;

  div {
    opacity: 0.8;
    border: 1px solid rgba(255, 255, 255, 0.3);

    padding: 8px 24px;
    text-align: center;
    margin-bottom: 12px;
    border-radius: 4px;
    cursor: pointer;
  }
}

.info {
  display: flex;
  align-items: center;
}
.name {
  img {
    width: 48px;
    margin-right: 12px;
  }
}
.tem {
  display: flex;
  align-items: center;

  & > div:first-child {
    font-size: 44px;
    margin-right: 4px;
  }
}
</style>