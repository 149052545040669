<template>
  <div class="container">
    <div class="panel">
      <div class="full">
        <Map />
      </div>
      <div class="two">
        <Weather />
      </div>
      <Air />
      <div class="two">
        <News
          :images="newsImages"
          :list="newsList"
          v-if="newsList.length > 0"
        />
      </div>
      <Cloud />
      <div class="full">
        <Category />
      </div>

      <Card title="天气预报" :extra="false">
        <div class="video" @click="openArticleDetail(weatherForecastVideo)">
          <img class="icon-play" src="../../assets/home/play.png" alt="" />
          <img class="bg" :src="weatherForecastVideo.face_img" alt="" />
        </div>
        <!-- <video v-if="weatherForecastVideo.url" :src="weatherForecastVideo.url" controls></video> -->
      </Card>
      <Card title="科普短片" url="/article-category?name=气象视频">
        <div class="video" @click="openArticleDetail(scienceVideo)">
          <img class="icon-play" src="../../assets/home/play.png" alt="" />
          <img class="bg" :src="scienceVideo.face_img" alt="" />
        </div>
        <!-- <video v-if="scienceVideo.url" :src="scienceVideo.url" controls></video> -->
      </Card>
      <Card title="热点视频" url="/article-category?name=气象视频">
        <div class="video" @click="openArticleDetail(hotVideo)">
          <img class="icon-play" src="../../assets/home/play.png" alt="" />
          <img class="bg" :src="hotVideo.face_img" alt="" />
        </div>
      </Card>

      <div class="full">
        <SpecialNews
          :width="1000"
          :list="specialNews"
          v-if="specialNews.length > 0"
        />
      </div>

      <div class="two">
        <Images :list="natureList" />
      </div>

      <HotImages :list="hotImageList" />

      <div class="two">
        <SSSWeather :list="sssWeatherList" />
      </div>
      <div>
        <Card title="特色产品" :extra="false">
          <router-link to="/products">
            <img style="width: 100%" src="../../assets/product5.png" alt="" />
          </router-link>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import article from "@/mixins/article";

import Images from "@/components/images";
import Map from "./components/map.vue";
import Weather from "./components/weather";
import Air from "./components/air";
import News from "@/components/news";
import Cloud from "./components/cloud.vue";
import Category from "./components/category";
import HotImages from "./components/hot-images.vue";
import SSSWeather from "./components/sss-weather.vue";
import SpecialNews from "@/components/special-news";

import { fetchList } from "@/api/article";
export default {
  mixins: [article],
  components: {
    Images,
    Map,
    Weather,
    Air,
    News,
    Cloud,
    Category,
    SpecialNews,
    HotImages,
    SSSWeather,
  },

  data() {
    return {
      newsImages: [], // 新闻
      newsList: [],

      specialNews: [], // 专题新闻

      weatherForecastVideo: {}, // 天气预报视频
      scienceVideo: {}, // 科普短片视频
      hotVideo: {}, // 热点视频
      natureList: [], // 自然风光图片列表

      hotImageList: [], // 热图排行

      sssWeatherList: [], // 苏苏说天气
    };
  },

  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      fetchList({
        p: [
          // 新闻
          {
            category: "qxxw",
            page: 1,
            rows: 7,
            face: 1,
          },
          {
            category: "qxxw",
            page: 1,
            rows: 7,
          },

          {
            // 专题新闻作为 swiper
            category: "ztxw",
            page: 1,
            rows: 10,
          },

          {
            // 视频-天气预报
            category: "tqyb",
            page: 1,
            rows: 1,
          },
          {
            // 视频-科普短片
            category: "kpdp",
            page: 1,
            rows: 1,
          },
          {
            category: "rdsp",
            page: 1,
            rows: 1,
          },
          {
            // 高清图集-自然风光
            category: "zrfg",
            page: 1,
            rows: 5,
            face: 1,
          },
          //     // 高清图集-天气现场
          //     category: "tqxc",
          //     // 高清图集-天气生活
          //     category: "tqsh",
          {
            // 热图排行，传递所有的分类
            category: "zrfg,tqxc,tqsh",
            page: 1,
            rows: 10,
            order: "article.views desc",
          },

          {
            category: "ssstq",
            page: 1,
            rows: 3,
          },
        ],
      }).then((res) => {
        if (Array.isArray(res) && res.length > 0) {
          if (res[0].data && Array.isArray(res[0].data.list)) {
            this.newsImages = res[0].data.list;
          }
          if (res[1] && res[1].data && Array.isArray(res[1].data.list)) {
            this.newsList = res[1].data.list;
          }

          let ztxw = res.find((item) => item.category === "ztxw");
          if (
            ztxw &&
            ztxw.data &&
            Array.isArray(ztxw.data.list) &&
            ztxw.data.list.length > 0
          ) {
            this.specialNews = ztxw.data.list;
          }

          let tqyb = res.find((item) => item.category === "tqyb");
          if (
            tqyb &&
            tqyb.data &&
            Array.isArray(tqyb.data.list) &&
            tqyb.data.list.length > 0
          ) {
            this.weatherForecastVideo = tqyb.data.list[0];
          }
          let rdsp = res.find((item) => item.category === "rdsp");
          if (
            rdsp &&
            rdsp.data &&
            Array.isArray(rdsp.data.list) &&
            rdsp.data.list.length > 0
          ) {
            this.hotVideo = rdsp.data.list[0];
          }

          let kpdp = res.find((item) => item.category === "kpdp");
          if (
            kpdp &&
            kpdp.data &&
            Array.isArray(kpdp.data.list) &&
            kpdp.data.list.length > 0
          ) {
            this.scienceVideo = kpdp.data.list[0];
          }
          let zrfg = res.find((item) => item.category === "zrfg");
          if (
            zrfg &&
            zrfg.data &&
            Array.isArray(zrfg.data.list) &&
            zrfg.data.list.length > 0
          ) {
            this.natureList = zrfg.data.list;
          }

          let hotImages = res.find(
            (item) => item.category === "zrfg,tqxc,tqsh"
          );
          if (
            hotImages &&
            hotImages.data &&
            Array.isArray(hotImages.data.list) &&
            hotImages.data.list.length > 0
          ) {
            this.hotImageList = hotImages.data.list;
          }

          let ssstq = res.find((item) => item.category === "ssstq");
          if (
            ssstq &&
            ssstq.data &&
            Array.isArray(ssstq.data.list) &&
            ssstq.data.list.length > 0
          ) {
            this.sssWeatherList = ssstq.data.list;
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.video {
  position: relative;
  cursor: pointer;
  .bg {
    width: 100%;
    height: 200px;
  }
  .icon-play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}
</style>